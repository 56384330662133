/**
 * Custom mixins.
 */

.header__site-slogan() {
  color: #fff;
  font-size: 28px;
  float: right;
  //margin-top: 50px;
  max-width: 350px;
  line-height: 1;
}

.header__site-slogan--large() {
  margin-top: 10px;
  font-size: 32px;
}

.heading-white() {
  color: #fff;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.8);
}

/**
 * Forms
 */
.select-arrow() {
  background-image: url(../images/select-arrow.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 30px 45px;
}