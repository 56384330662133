/**
 * Styles for the Landing Page content type.
 */

.landing_page__hero {
  //background: transparent url(../images/hero-bg.jpg) -120px top no-repeat;
  background: transparent url(../images/hero-bg.jpg) -50px top no-repeat;
  background-size: auto 100%;
  height: 100%;
  position: relative;
  //min-height: 660px;
  
  &.jumbotron {
    margin-bottom: 0;
    //padding-top: 0;
    padding-top: 200px;
    padding-bottom: 0;
    
    h1 {
      color: @brand-warning;
      text-transform: uppercase;
      margin: 7px 0;
      font-size: 18px;
    }
  }
  
  .block-title {
    text-align: center;
    color: @brand-info;
    text-transform: uppercase;
    margin-bottom: 40px;
    font-size: 24px;
  }
}

.page-full-expandable-form {
  
  .landing_page__hero {
    
    &.jumbotron {
      padding-top: 180px;
    }
  }
}

.woman-2-bg {
  
  .landing_page__hero {
    background: url(../images/hero-bg2.jpg) -570px top no-repeat;
    background-size: auto 100%;
  }
}

.landing_page__menu-top {
  display: none;
  float: right;
  margin: -24px 24px 0 0;
}

.landing_page__menu-bottom {
  
  .login-link-block,
  .block-menu-block {
    margin: 0 auto;
    max-width: 76px;
    //padding-bottom: 30px;
    padding-bottom: 5px;
    font-size: 14px;
    
    .nav-pills {
      
      > li {
        
        > a {
          background: transparent;
          color: #fff;
        }
      }
    }
  }
}

.landing_page__hero-inner {
  background: url(../images/form-wrapper-bg.png) center center no-repeat;
  background-size: cover;
  //height: 660px;
  //position: absolute;
  //top: 20px;
  //left: 0;
  margin: auto;
  //padding: 0 20px;
  padding: 10px 0;
  width: 100%;
  z-index: 999;
}

.landing_page__logo {
  background: url(../images/logo-01.svg) center center no-repeat;
  background-size: contain;
  //width: 242px;
  //height: 75px;
  width: 194px;
  height: 60px;
  max-width: 100%;
  //margin: 10px auto;
  margin: 0 auto 7px auto;
}

.domain-milfs365-com-au {
  
  .landing_page__logo {
    background-image: url(../images/365-logo.png);
  }
}

@media (min-width: 480px) {
  
  .landing_page__hero {
    
    &.jumbotron {
      
      h1 {
        margin: 10px 0;
        font-size: 24px;
      }
    }
  }
  
  .woman-2-bg {
  
    .landing_page__hero {
      background-position: -500px top;
    }
  }
}

@media (min-width: 600px) {
  
  .landing_page__hero {
    
    &.jumbotron {
      
      h1 {
        font-size: 36px;
        margin-bottom: 20px;
      }
    }
  }
  
  .woman-2-bg {
  
    .landing_page__hero {
      background-position: right top;
      background-size: cover;
    }
    
    &.hero-only {
      
      .landing_page__hero {
        background-position: -650px top;
        background-size: cover;
      }
    }
  }
}

@media (min-width: 600px) and (min-height: 800px) {
  
  .hero-only {
    
    .landing_page__hero-inner {
      position: absolute;
      bottom: 0;
    }
  }
}

@media (min-width: @screen-sm-min) {
  
  .landing_page__hero {
    
    &.jumbotron {
      
      h1 {
        font-size: 42px;
      }
    }
  }
  
  .woman-2-bg {
  
    &.hero-only {
      
      .landing_page__hero {
        background-position: -800px top;
      }
    }
  }
  
  .landing_page__logo {
    width: 323px;
    height: 100px;
    //margin-bottom: 50px;
    margin-bottom: 20px;
  }
}

@media (min-width: 800px) {
  
  .woman-2-bg {
  
    &.hero-only {
      
      .landing_page__hero {
        background-position: right top;
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  
  .landing_page__hero {
    min-height: 600px;
    background-position: left top;
    background-size: cover;
    
    &.jumbotron {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }
  
  .woman-2-bg {
  
    .landing_page__hero {
      background-position: right top;
    }
  }
  
  .landing_page__menu-top {
    display: block;
    position: relative;
    z-index: 1000;
  }
  
  .landing_page__menu-bottom {
    display: none;
  }
  
  .landing_page__hero-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 600px;
    
    &.fixed {
      position: fixed;
      height: 180px;
      margin: 0;
      padding: 10px 0;
      box-sizing: border-box;
      bottom: auto;
      -webkit-transition: all 200ms ease-in-out 100ms;
      -moz-transition: all 200ms ease-in-out 100ms;
      -ms-transition: all 200ms ease-in-out 100ms;
      -o-transition: all 200ms ease-in-out 100ms;
      transition: all 200ms ease-in-out 100ms;
      
      .container {
        width: 100%;
      }
      
      .landing_page__logo {
        display: none;
      }
      
      h1,
      h2,
      .terms-conditions {
        display: none;
      }
    }
  }
  
  .page-expandable-form-only {
    
    .landing_page__hero-inner {
      height: 700px;
      
      &.fixed {
        height: 180px;
      }
    }
  }
  
  .landing_page__logo {
    height: 150px;
    width: 484px;
    margin-top: 20px;
  }
}

@media (min-width: @screen-lg-min) {
  
  .woman-2-bg {
  
    .landing_page__hero {
      background-position: right top;
    }
  }
  
  .landing_page__hero-inner {
    
    &.fixed {
      
      .landing_page__logo {
        width: 323px;
        height: 100px;
        margin: 25px 0 0 0;
        float: left;
        display: block;
      }
    }
  }
}