/**
 * Block styles.
 */

/**
 * WLD Double Form.
 */
.block-wld-double-form {
  
  .block-title,
  .wld-question-form {
    display: none;
  }
}

@media (min-width: @screen-md-min) {
  
  .block-wld-double-form {
    
    .block-title,
    .wld-question-form {
      display: block;
    }
    
    .wld-expandable-join-form {
      display: none;
    }
  }
}

/**
 * Footer logo block.
 */

.footer-logo {
  background: url(../images/logo-01.svg) center center no-repeat;
  background-size: contain;
  height: 60px;
  width: 194px;
  max-width: 100%;
  float: left;
}

.domain-milfs365-com-au {
  
  .footer-logo {
    background-image: url(../images/365-logo.png);
  }
}

.scroll-to-top {
  background: @brand-info;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  float: left;
  margin-left: 20px;
  box-sizing: border-box;
  
  a {
    display: block;
    padding: 15px 0 0 0;
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 60px;
    outline: none;
  }
  
  .glyphicon {
    font-size: 30px;
  }
}

#block-copyright-block-copyright-block {
  font-size: 10px;
  color: @gray;
  padding: 0 15px;
}

@media (min-width: @screen-sm-min) {
  
  #block-milfs-blocks-footer-logo {
    float: left;
  }
  
  .footer-country-menu {
    float: right;
  }
  
  #block-copyright-block-copyright-block {
    float: right;
    clear: right;
  }
}
