/**
 * Tables.
 */

.table > tbody > tr > td.vert-middle {
  vertical-align: middle;
}

/**
 * Margins and spacing.
 */

.top-15 {
  margin-top: 15px;
}

.top-30 {
  margin-top: 30px;
}

.bottom-15 {
  margin-bottom: 15px;
}

.bottom-30 {
  margin-bottom: 30px;
}

/**
 * On scroll animations.
 */
.toAnim {
    opacity: 0;
    filter: alpha(opacity=0);
    transition: opacity 1s ease 0s;
}
.toAnim.anim {
    opacity: 1;
    filter: alpha(opacity=100);
}
