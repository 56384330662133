/**
 * Page styles.
 */

html,
body {
  height: 100%;
  width: 100%;
}

.navbar-header {
  width: 100%;
}

/**
 * Mobile lander styles.
 */
.node-type-mobile-lander {
  
  .navbar {
    margin-bottom: 0;
  }
  
  .navbar-default {
    border: none;
  }
  
  .navbar-header {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  
  .logo-image {
    padding: 0 10px;
    height: 60px;
    margin: 0 auto;
  }
  
  .main-container  {
    padding-bottom: 20px;
  }
  
  .page-header {
    border: none;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    color: @brand-info;
    padding: 0 10px 10px 10px;
    margin: 0;
  }
}

@media (min-width: 600px) {
  
  .node-type-mobile-lander {
    
    .logo-image {
      height: 100px;
    }
    
    .page-header {
      font-size: 36px;
      padding: 10px 20px 20px 20px;
    }
  }
}

/**
 * Footer.
 */

.footer {
  border: none;
  font-size: 12px;
}
