/**
 * Styles for the Mobile Lander content type.
 */

.mobile-lander__images-wrapper {
  background: url(../images/form-wrapper-bg.png) center center no-repeat;
  background-size: cover;
}

.mobile-lander__images {
  max-width: 500px;
  margin: 0 auto 10px auto;
}

.mobile-lander__image {
  width: 50%;
  float: left;
  
  img {
    margin: 0 auto;
    padding: 10px;
    width: 100%;
  }
}

.mobile-lander__form {
  padding: 0 10px;
  
  .block-title {
    font-size: 24px;
    text-align: center;
    color: @brand-primary;
    margin-bottom: 10px;
  }
}

@media (min-width: 480px) {
  
  .mobile-lander__image {
    
    img {
      width: auto;
    }
  }
}
