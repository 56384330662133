/**
 * Styles for paragraphs.
 */

.paragraphs-item-text-only {
  padding: 40px 0;
}

.paragraphs-text-only-text {
  max-width: 900px;
}

.paragraph-row-heading {
  text-transform: uppercase;
  font-size: 24px;
  margin: 0 0 30px 0;
}

.with-background {
  
  .paragraph-row-heading,
  .paragraphs-text-only-text {
    color: #fff;
  }
}

.animal-print-bg {
  background: url(../images/animal-print-bg.png) center center no-repeat;
  background-size: cover;
}

@media (min-width: @screen-sm-min) {
  
  .paragraphs-item-text-only {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/**
 * Text with features
 */
.text-with-features {
  background: url(../images/text-with-features-bg.jpg) -1200px top no-repeat;
}

.text-with-features__inner {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 40px 0;
}

.woman-2-bg {
  
  .text-with-features {
    background: url(../images/text-with-features-bg2.jpg) right top no-repeat;
  }
}

.text-with-features__item {
  max-width: 625px;
}

.text-with-features__features {
  margin-top: 40px;
  
  li {
    background: @brand-info url(../images/feature1-01.svg) 15px center no-repeat;
    background-size: 25px auto;
    border-radius: 30px;
    color: @brand-primary;
    text-transform: uppercase;
    padding: 10px 10px 10px 70px;
    max-width: 350px;
    margin-bottom: 10px;
    
    &:nth-child(2) {
      background-image: url(../images/feature2-02.svg);
    }
    
    &:nth-child(3) {
      background-image: url(../images/feature3-03.svg);
    }
  }
}

@media (min-width: 600px) {
  
  .text-with-features {
    background-position: -400px top;
    background-size: cover;
  }
}

@media (min-width: @screen-sm-min) {
  
  .text-with-features {
    background-position: -600px top;
  }
  
  .text-with-features__inner {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  
  .text-with-features__features {
    margin-top: 80px;
  }
}

@media (min-width: @screen-md-min) {
  
  .text-with-features {
    background-position: -400px top;
  }
  
  .text-with-features__inner {
    background-color: transparent;
  }
}

@media (min-width: @screen-lg-min) {
  
  .text-with-features {
    background-position: right top;
  }
}

/**
 * Chat row.
 */

.chat-row {
  background: @brand-info;
}

.chat-row__chat {
  padding: 40px 0;
}

.chat-row__chat-images {
  padding-top: 10px;
}

.chat-row__chat-image {
  margin-bottom: 20px;
}

.chat-row__phone {
  display: none;
}

@media (min-width: @screen-sm-min) {
  
  .chat-row__chat {
    padding-top: 100px;
  }
  
  .chat-row__chat-images {
    margin-top: 40px;
  }
  
  .chat-row__phone {
    display: block;
    background: url(../images/phone-bg.png) center top no-repeat;
    background-size: cover;
    
    img {
      display: inline-block;
    }
  }
}

@media (min-width: @screen-lg-min) {
  
  .chat-row__phone {
    //margin-right: -150px;
    //float: right;
  }
}