/**
 * Photo BG Lander styles.
 */

.node-type-photo-bg-lander {
  background: url(../images/hero-bg2.jpg) -1100px 100px no-repeat;
  display: block;
  
  .navbar {
    margin-bottom: 10px;
    height: 100px;
  }

  .navbar-default {
    background: #fff url(../images/form-wrapper-bg.png) center center no-repeat;
    background-size: cover;
    border: none;
  }
  
  .logo-image {
    max-height: 80px;
    float: left;
  }
  
  .header__site-slogan {
    .header__site-slogan();
  }
}

.field-pbl-strap-line {
  max-width: 400px;
}

.pbl-join-form {
  margin-bottom: 20px;
}

.field-pbl-box-headline {
  background-color: @primary-bg;
}

.down-arrow {
  border-top: 20px solid @brand-primary;
}

@media (min-width: @screen-sm-min) {
  
  .node-type-photo-bg-lander {
    background-size: cover;
  }
}

@media all and (min-width: 768px) {
  
  .node-type-photo-bg-lander {
    background-position: -900px 100px;
  }
}

@media all and (min-width: 800px) {
  
  .node-type-photo-bg-lander {
    background-position: -100px 100px;
    
    .navbar {
      margin-bottom: 40px;
      height: 100px;
    }
    
    .header__site-slogan {
      display: block;
    }
  }
  
  .field-name-field-pbl-form {
    margin-top: 20px;
  }
  
  .pbl-join-form__arrow {
    opacity: 0.9;
  }
  
  .pbl-join-form__form {
    background: rgba(255,255,255,0.9);
  }
  
  .field-pbl-strap-line {
    display: none;
  }
}

@media all and (min-width: @screen-lg-min) {
  .node-type-photo-bg-lander {
    background-position: right 100px;
  }
}

@media (min-width: @screen-md-min) {
  
  .node-type-photo-bg-lander {
    
    .header__site-slogan {
      .header__site-slogan--large();
    }
  }
}