/**
 * Form styles.
 */

.terms-conditions {
  color: #f8f8f8;
  
  a {
    color: #f8f8f8;
  }
}

.node-type-mobile-lander {
  
  .terms-conditions {
    color: #ccc;
    
    a {
      color: #ccc;
    }
  }
}

.node-type-photo-bg-lander {
  
  .terms-conditions {
    color: @text-color;
    
    a {
      color: @text-color;
    }
  }
}

.simform {
  
  .control-label,
  .panel-title,
  .gender-question-label {
    display: block;
    text-align: center;
    font-weight: normal;
    height: 30px;
    color: #fff;
    font-size: 20px;
    line-height: 1;
    //padding: 5px 0;
    text-transform: uppercase;
  }
  
  .next {
    top: 30px;
  }
  
  .progress {
    margin-bottom: 5px;
  }
  
  .number {
    color: #fff;
  }
  
  // Bootstrap overrides, couldn't remove the fucking classes.
  .panel {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }
  
  .panel-default {
    
    > .panel-heading {
      background: transparent;
      color: inherit;
    }
  }
  
  .panel-heading {
    border: none;
    padding: 0;
    float: none;
    line-height: inherit;
  }
  
  .panel-title {
    //font-size: 12px;
  }
  
  .panel-body {
    padding: 0;
  }
  
  .form-control {
    
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.gender-label {
  padding-top: 20px;
  text-transform: uppercase;
}

/**
 * Expandable join form.
 */

@form-item-height-small: 34px;
@form-item-height-large: 43px;
@form-font-size-small: 12px;
@form-font-size-large: 14px;

.wld-expandable-join-form {
  font-size: @form-font-size-small;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  
  label {
    display: block;
    text-align: left;
  }
  
  .form-control {
    font-size: @form-font-size-small;
    padding: 6px 10px;
    height: @form-item-height-small;
  }
  
  .form-group {
    margin-bottom: 7px;
  }
  
  .form-inline {
    .clearfix();
    
    .form-item {
      float: left;
    }
    
    .form-control {
      width: 100%;
    }
    
    .form-group {
      margin-bottom: 0;
    }
  }
  
  .form-item-dob-day {
    width: 30%;
    padding-right: 5px;
  }
  
  .form-item-dob-month {
    width: 40%;
    padding-right: 5px;
  }
  
  .form-item-dob-year {
    width: 30%;
  }
  
  .btn {
    margin-top: 10px;
  }
  
  .terms-conditions {
    top: 0;
    //margin: 30px 0;
    margin: 30px 0 0 0;
    font-size: 9px;
  }
  
  &.full-expandable-form {
    color: @text-color;
    
    .form-group {
      margin-bottom: 10px;
    }
    
    label {
      display: none;
    }
    
    .form-item-dob {
      .clearfix;
      
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  
  /*Placeholder Styling*/
  ::-webkit-input-placeholder {
    color: @text-color;
    -webkit-font-smoothing: antialiased;
    opacity: 1;
  }
  
  ::-moz-placeholder {
    color: @text-color;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: @text-color;
    opacity: 1;
  }
  
  input:-moz-placeholder {
    color: @text-color;
    opacity: 1;
  }
}

@media (min-width: 480px) {
  
  .wld-expandable-join-form {
    
    &.full-expandable-form {
      
      .form-group {
        //margin-bottom: 15px;
      }
    
      .form-item-dob {
        background: #fff;
        border-radius: @input-border-radius;
        border: 1px solid @input-border;
        height: @form-item-height-small;
        
        label {
          display: block;
          float: left;
          width: 20%;
          font-weight: normal;
          padding: 0 10px;
          box-sizing: border-box;
          line-height: @form-item-height-small;
          margin-bottom: 0;
        }
        
        .form-control {
          height: @form-item-height-small - 2;
        }
        
        .form-inline {
          width: 80%;
          float: right;
          box-sizing: border-box;
          
          .form-control {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  
  .wld-expandable-join-form {
    font-size: @form-font-size-large;
    
    .form-control {
      height: @form-item-height-large;
      font-size: @form-font-size-large;
    }
    
    .form-item-dob-day {
      width: 25%;
    }
    
    .form-item-dob-month {
      width: 50%;
    }
    
    .form-item-dob-year {
      width: 25%;
    }
    
    &.full-expandable-form {
      
      .form-item-dob {
        height: @form-item-height-large;
        
        label {
          line-height: @form-item-height-large;
        }
        
        .form-control {
          height: @form-item-height-large - 2;
        }
      }
    }
  }
}

/**
 * Flexslider form.
 */

.wld-flexslider-join-form {
  
  .panel {
    background: transparent;
    .box-shadow(none);
    width: 380px;
  }
  
  .panel-body {
    padding: 0 30px;
  }
  
  .form-item {
    position: relative;
  }
  
  label {
    display: block;
    text-align: center;
  }
  
  label.error {
    top: 90px;
    font-size: 11px;
  }
  
  .next-button,
  .form-submit {
    .btn();
    .btn-primary();
    width: 150px;
    margin: 0 auto;
    display: block;
  }
  
  .form-control {
    height: auto;
  }
  
  .form-inline {
    .clearfix();
    
    .form-group {
      float: left;
    }
    
    .form-control {
      display: block;
      width: 100%;
    }
  }
  
  .form-item-birthday-dob-day {
    width: 25%;
    padding-right: 5px;
  }
  
  .form-item-birthday-dob-month {
    width: 50%;
    padding-right: 5px;
  }
  
  .form-item-birthday-dob-year {
    width: 25%;
  }
}

/**
 * WLD GDPR Five Step Form
 */

.wld-forms-five-step-form {
  /*Placeholder Styling*/
  ::-webkit-input-placeholder {
    color: @text-color;
    -webkit-font-smoothing: antialiased;
    //opacity: 1;
  }
  
  ::-moz-placeholder {
    color: @text-color;
    -moz-osx-font-smoothing: grayscale;
    //opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: @text-color;
    //opacity: 1;
  }
  
  input:-moz-placeholder {
    color: @text-color;
    //opacity: 1;
  }
}

.wld-button {
  background-color: @brand-primary;
}

.wld-form__suffix {
  margin-top: 15px;
}