/**
 * Styles for navigation.
 */

/**
 * Footer country menus.
 */
.footer-country-menu {
  
  .nav-pills {
    margin: 20px 0;
    
    > li {
      float: none;
    }
  }
  
  a:link,
  a:visited {
    color: @brand-info;
  }
}

@media (min-width: @screen-sm-min) {
  
  .footer-country-menu {
    
    .nav-pills {
      margin-top: 0;
      
      > li {
        float: left;
      }
    }
  }
}

/**
 * WLD Login Link
 */

@media (min-width: @screen-md-min) {

  .wld-login-link {
    color: #fff;
    background-color: @brand-primary;
    
    &:hover {
      color: #fff;
      background-color: darken(@brand-primary, 5%) !important;
    }
  }
}